.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-right: 0em solid transparent !important;
    border-bottom: 0;
    border-left: 0em solid transparent !important;
}

.btn-calcColor {
    color: #FFFFFF !important;
    background-color: #1DC7EA !important;
    opacity: 1 !important;
    margin-right: 10px !important;
}

.ct-vertical {
    height: 30px !important;
    width: 40px !important;
    text-align: left !important;
}

.btn-link {
    text-decoration: none !important;
}

.custom-nav {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    font-size: 12px !important;
    text-anchor: start;
}

.ct-label.ct-vertical.ct-start {
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    text-anchor: end;
    font-size: 12px !important;
}



.sidebar:after,
body>.navbar-collapse:after {
    background: #9368e900 !important;
    /* background: linear-gradient(to bottom, #9368E9 0%, #943bea 100%); */
    background-size: 150% 150%;
    z-index: 3;
    opacity: 1;
}



.blury-effect {
    background-size: cover;
    background-position: center;
    filter: blur(10px);
    pointer-events: none;
}


.rmsc {
    --rmsc-main: #AAAAAA!important;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-bg: #fff;
    --rmsc-p: 10px;
    --rmsc-radius: 4px;
    --rmsc-h: 38px;
}

.form-check-label {
    padding-left: 10px!important;
}

.swal2-title{
    font-size: 20px!important;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif !important;
}
.PhoneInputInput{
    border: none!important;
}
.sidebar-background{
    background-size: cover!important;
}