
.select_box_wrapper
{
    width: 100%;
    height: 40px;
    position: relative;
}
.custom_select_box
{
    width: 100%;
    height: 40px;
    border-radius: 3px;
    border: 1px solid #ced4da;
    display: flex;
    align-items: center;
    padding: 0 8px;
    justify-content: space-between;
    background: #fff;
    transition: all 300ms;
    cursor: pointer;
}
.custom_select_dropdown
{
    border:none;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    margin-top: 5px;
    border-radius: 1px;
    height: 160px;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    transition: all 300ms;
    display: block;
    position: absolute;
    left: 0;
    background: #fff;
    width: 100%;
    z-index: 9999999999999;
}
.custom_select_dropdown_list_wrapper
{
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    padding: 8px 10px;
}
.custom_select_dropdown_list
{
    padding: 10px 0 10px 10px;
    position: relative;
    transition: all 100ms;
    color: #9A9A9A;
    border-radius: 1px;
    cursor: pointer;
}
.custom_select_dropdown_list:hover
{
    background: #42D0ED;
    color: white!important;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
}
.custom_select_dropdown_list_radio
{
    width: 100%;
    position: absolute;
    opacity: 0;
    margin: 0;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
}
.custom_select_box.select_box_active
{
    border-bottom: 2px solid #4285f4;
}
.custom_select_box.select_box_active + .custom_select_dropdown
{
    display: block;
   animation: animateDrop 300ms forwards;
}
@keyframes animateDrop 
{
    from 
    {
        opacity: 0;
        height: 0;
    }
    to 
    {
        opacity: 1;
        height: 160px;
    }
}
.custom_select_box_selected_label
{
    width: 94%;
}
.custom_select_box_dropdown_icon
{
    width: 5%;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}
.custom_select_box_dropdown_icon_button
{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    font-size: 24px;
    transition: all 300ms;
    color: #313131;
}
.custom_select_dropdown_list_search_input
{
    width: 100%;
    height: 30px;
    padding-left: 8px;
    border: none;
    outline: none;
    font-size: 14px;
    transition: all 300ms;
    border-bottom: 1px solid #cacaca80;
}
.custom_select_dropdown_list_search_input:focus
{
    border-bottom: 1px solid #4285f4;
}
.custom_select_dropdown_list_search
{
    margin-bottom: 4px;
}
.custom_select_dropdown_list_search_input::placeholder
{
    opacity: 1;
    transition: all 300ms ease-in-out;
}
.custom_select_dropdown_list_search_input:focus::placeholder
{
    opacity: 0;
}
.custom_select_dropdown::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.custom_select_dropdown::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.custom_select_dropdown::-webkit-scrollbar-thumb
{
    background-color: #31313150;
    border-radius: 10px;
}
.custom_select_box.select_box_active .custom_select_box_dropdown_icon .custom_select_box_dropdown_icon_button i
{
    animation: animateselectIcon 300ms forwards;
    transform-origin: center;
}
@keyframes animateselectIcon
{
    from
    {
        transform: rotateZ(0);
        color: #313131;
    }
    to 
    {
        transform: rotateZ(180deg);
        color: #4285f4;
    }
}
.custom_select_box .custom_select_box_dropdown_icon .custom_select_box_dropdown_icon_button i
{
    animation: animateselectIconBack 300ms forwards;
    transform-origin: center;
}
@keyframes animateselectIconBack
{
    from
    {
        transform: rotateZ(180deg);
        color: #4285f4;
    }
    to 
    {
        transform: rotateZ(0deg);
        color: #313131;
    }
}