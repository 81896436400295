#file-upload {
    display: none;
}

#file-upload-logo {
    display: none;
}

.custom-file-upload {
    border: 3px solid #ccc;
    display: inline-block;
    border-radius: 50px;
    padding: 10px 8px 6px 8px;
    cursor: pointer;
    margin-right: -70px;
    position: absolute;
    top: 41%;
    left: 56%;
    background-color: white;
}

.custom-file-upload-logo {
    border: 3px solid #ccc;
    display: inline-block;
    border-radius: 50px;
    padding: 10px 8px 6px 8px;
    cursor: pointer;
    margin-right: -70px;
    position: absolute;
    top: 41%;
    left: 56%;
    background-color: white;
}