/* Set a fixed scrollable wrapper */
.tableWrap {
    height: 400px;
    overflow-x: auto;
    overflow-y: auto;
  }
  /* Set header to stick to the top of the container. */
  thead tr th {
    position: sticky;
    top: 0;
  }
  
  /* If we use border,
  we must use table-collapse to avoid
  a slight movement of the header row */
  table {
   border-collapse: collapse;
  }
  
  
  th {
    
	font-weight: 500;
   padding-top: 8px!important;
   padding-bottom: 8px!important; 
   background: white!important;
    text-align: left;
    color:#9A9A9A!important;
    box-shadow: 0px 0px 0 2px #e8e8e8;
    z-index: 99;
  }
  
  /* Basic Demo styling */
  table {
    width: 100%;
  }
  table td {
    padding: 16px;
  }
  tbody tr {
    border-bottom: 2px solid #e8e8e8;
  }
  thead {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  tbody tr:hover {
    background: #e6f7ff;
  }