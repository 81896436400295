body {}

.pricing-table {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: min(1600px, 100%);
  margin: auto;
}

.pricing-card {


  flex: 1;
  max-width: 360px;
  background-color: #fff;
  margin: 20px 10px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  color: #2d2d2d;
  transition: .3s linear;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  border-color: #0fbcf9;
}

.pricing-card-header {
  background-color: #0fbcf9;
  display: inline-block;
  color: #fff;
  padding: 12px 30px;
  border-radius: 0 0 20px 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  transition: .4s linear;
}



.pricing-card[data-color="azure"]:hover .pricing-card-header {
  box-shadow: 0 0 0 21em #1DC7EA;
}

.pricing-card[data-color="green"]:hover .pricing-card-header {
  box-shadow: 0 0 0 21em #87CB16;
}


.pricing-card[data-color="orange"]:hover .pricing-card-header {
  box-shadow: 0 0 0 21em #FFA534;
}

.pricing-card[data-color="red"]:hover .pricing-card-header {
  box-shadow: 0 0 0 21em #FB404B;
}

.pricing-card[data-color="purple"]:hover .pricing-card-header {
  box-shadow: 0 0 0 21em #9368E9;
}



.order-btn[data-color="azure"]:hover {
  background-color: #1DC7EA;
  color: #fff !important;
}

.order-btn[data-color="green"]:hover {
  background-color: #87CB16;
  color: #fff !important;
}

.order-btn[data-color="orange"]:hover {
  background-color: #FFA534;
  color: #fff !important;
}

.order-btn[data-color="red"]:hover {
  background-color: #FB404B;
  color: #fff !important;
}

.order-btn[data-color="purple"]:hover {
  background-color: #9368E9;
  color: #fff !important;
}

.price {
  font-size: 70px;
  color: #0fbcf9;
  margin: 40px 0;
  transition: .2s linear;
}

.price sup,
.price span {
  font-size: 22px;
  font-weight: 700;
}

.pricing-card:hover,
.pricing-card:hover .price {
  color: #fff !important;
}

.pricing-card li {
  font-size: 16px;
  padding: 10px 0;
  text-transform: uppercase;
}

.order-btn {
  display: inline-block;
  margin-bottom: 40px;
  margin-top: 80px;
  border: 2px solid #0fbcf9;
  color: #0fbcf9;
  padding: 18px 40px;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 500;
  transition: .3s linear;
}



@media screen and (max-width:1100px) {
  .pricing-card {
    flex: 50%;
  }
}